import find from 'lodash/find';
import { art as ART_ENUM, artist as ARTIST_ENUM } from '../../../config/enumeration.js';
import { components as COMPONENTS_CONFIG } from '../../../config/config.js';

export const AUTO_HEIGHT = 'AUTO';
export const IMAGE_FORMAT_WEBP = 'webp';

/**
 * extractImageFileFromData
 */
export const extractImageFileFromData: Record<string, any> = {
  artMaster: (
    artImages: Record<string, any>[],
    type = 'MAIN_MASTER',
  ): Record<string, any> | undefined => {
    let foundImage = null;
    // @ts-ignore
    const imgType = ART_ENUM.image.type[type];

    foundImage =
      artImages &&
      artImages.reduce((accumulator: Record<string, any> | null, image: Record<string, any>) => {
        if (accumulator) {
          return accumulator;
        }

        if (image.type === imgType) {
          accumulator = { ...image };
        } else if (image.children) {
          const foundItem = find(image.children, { type: imgType });

          if (foundItem) {
            accumulator = { ...foundItem };
          }
        }

        return accumulator;
      }, null);

    // Return found image or the fallback image if no image was found
    return (
      (foundImage && foundImage.file) ||
      find(COMPONENTS_CONFIG.art.fallbackImages, { type: imgType })
    );
  },
  artDetail: (artImages: Record<string, any>[], type = 'DETAIL_MASTER') => {
    // @ts-ignore
    const imgType = ART_ENUM.image.type[type];

    return artImages.reduce(
      (accumulator: Record<string, any>[], image: Record<string, any>): Record<string, any>[] => {
        if (image.type === imgType) {
          accumulator = [...accumulator, image];
        } else if (image.children) {
          const foundItem = find(image.children, { type: imgType });

          if (foundItem) {
            accumulator = [...accumulator, foundItem];
          }
        }

        return accumulator;
      },
      [],
    );
  },
  artistFeed: (images: Record<string, any>[], type = 'ORIGINAL') => {
    // @ts-ignore
    return find(images, { type: ARTIST_ENUM.feed.image.type[type] });
  },
  byType: (images: Record<string, any>[], type: string): Record<string, any> | undefined =>
    find(images, { type }),
};
